<template>
  <Dialog
    v-model:visible="displayModal"
    :modal="true"
    :closeOnEscape="true"
    :dismissableMask="true"
    :breakpoints="{'1300px': '100vw'}" :style="{width: '90vw'}"
  >
    <template #header>
      <div style="color:#1c3faa;">
        <h4>
          <i class="pi pi-file" style="font-size: 1.5rem;margin-right:10px"></i>
          <strong style="font-size:20px">Detalles del pedido</strong>
        </h4>
      </div>
    </template>
    <Card class="w-full">
      <template #content>
        <div class="w-full text-xxs">
          <div class="grid grid-cols-1 xl:grid-cols-2 lg:gap-x-10 xl:gap-x-60">
            <div class="grid grid-rows-4 gap-1 pt-3">
              <div class="grid grid-cols-4 items-center">
                <div class="col-span-1">
                  <strong>Código Cliente/Entidad: </strong>
                </div>
                <div class="col-span-3">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="detailsPedido.CardCode" disabled/>
                </div>
              </div>
              <div class="grid grid-cols-4 items-center">
                <div class="col-span-1">
                  <strong>Nombre Cliente/Entidad: </strong>
                </div>
                <div class="col-span-3">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="detailsPedido.CardName" disabled/>
                </div>
              </div>
              <div class="grid grid-cols-4 items-center">
                <div class="col-span-1">
                  <strong>Usuario: </strong>
                </div>
                <div class="col-span-3">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="detailsPedido.U_PHR_UserWs"
                             disabled/>
                </div>
              </div>
              <div class="grid grid-cols-4 items-center">
                <div class="col-span-1">
                  <strong>Numero de pedido: </strong>
                </div>
                <div class="col-span-3">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="detailsPedido.DocNum" disabled/>
                </div>
              </div>
            </div>
            <div class="grid grid-rows-4 gap-1 pt-1">
              <div class="grid grid-cols-4 items-center">
                <div class="xl:col-end-4 col-span-1">
                  <strong>Fecha de Contabilización: </strong>
                </div>
                <div class="xl:col-end-5 col-span-3 xl:col-span-1">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="detailsPedido.TaxDate" disabled/>
                </div>
              </div>
              <div class="grid grid-cols-4 items-center">
                <div class="xl:col-end-4 col-span-1">
                  <strong>Fecha de documento: </strong>
                </div>
                <div class="xl:col-end-5 col-span-3 xl:col-span-1">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="detailsPedido.DocDate" disabled/>
                </div>
              </div>
              <div class="grid grid-cols-4 items-center">
                <div class="xl:col-end-4 col-span-1">
                  <strong>Fecha de vencimiento: </strong>
                </div>
                <div class="xl:col-end-5 col-span-3 xl:col-span-1">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="detailsPedido.DocDueDate" disabled/>
                </div>
              </div>
              <div class="grid grid-cols-4 items-center">
                <div class="xl:col-end-4 col-span-1">
                  <strong>Estado: </strong>
                </div>
                <div class="xl:col-end-5 col-span-3 xl:col-span-1">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="detailsPedido.DocStatus" disabled/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%" class="mt-6">
          <div>
            <DataTable
              :value="detailsPedido.detalle"
              class="p-datatable-sm text-xxs"
              showGridlines
              dataKey="LineNum"
              responsiveLayout="scroll"
              :scrollable="true"
              scrollHeight="500px"
              v-model:selection="selectedCustomers"
              :rowClass="rowClass"
              @row-select="onRowSelect"
              @row-select-all="onRowSelectAll"
            >
              <template #empty>
                No hay datos para mostrar
              </template>
              <Column
                v-if="$can('pharmasan.compras.logistica.recepcion-pedidos.listado-pedidos.cerrar-lineas')"
                selectionMode="multiple"
                bodyStyle="text-align: center;justify-content: center;"
                headerStyle="text-align: center;justify-content: center;"
                style="min-width: 3rem;"
              />
              <Column field="LineNum" header="Indice" headerStyle="justify-content: center;text-align: center"
                      style="min-width:3rem;"
                      bodyStyle="justify-content: center;text-align: center">
                <template #body="{data}">
                  <div>{{ data.LineNum }}</div>
                </template>
              </Column>
              <Column field="ItemCode" header="Artículo" headerStyle="justify-content: center;text-align: center"
                      align="left" style="min-width:32rem">
                <template #body="{data}">
                  <div class="grid grid-cols-1 w-full">
                    <div class="flex justify-between items-center my-1">
                      <div>
                        <strong>Código Mx: </strong>{{ data.ItemCode }}
                      </div>
                      <div class="flex gap-2">
                        <Badge v-if="data.ReqMarca" :value="`Req. marca`"
                               class=" bg-red-300 text-red-700 text-xxs h-4 capitalize"/>
                        <Badge v-if="data.Controlado" value="Controlado"
                               class=" bg-green-300 text-green-700 text-xxs h-4 capitalize"></Badge>
                        <Badge v-if="data.Regulado" value="Regulado"
                               class=" bg-blue-300 text-red-blue text-xxs h-4 capitalize"></Badge>
                        <Badge v-if="data.cadenaFrio" value="Cadena Frio"
                               class="bg-blue-800 text-blue text-xxs h-4"></Badge>
                      </div>
                    </div>
                    <div>
                      <strong>Nombre: </strong>{{ data.ItemName }}
                    </div>
                  </div>
                </template>
              </Column>
              <Column field="Quantity" header="Cantidad" headerStyle="justify-content: center;text-align: center"
                      style="min-width:5rem"
                      bodyStyle="justify-content: center;text-align: center">
                <template #body="{data}">
                  <div>{{ $h.formatNumber(data.Quantity) }}</div>
                </template>
              </Column>
              <Column field="cantidadGestionada" header="Cant. Gestionada"
                      headerStyle="justify-content: center;text-align: center"
                      style="min-width:8rem"
                      bodyStyle="justify-content: center;text-align: center">
                <template #body="{data}">
                  <div>{{ $h.formatNumber(data.cantidadGestionada) }}</div>
                </template>
              </Column>
              <Column field="Price" header="Precio" headerStyle="justify-content: center;text-align: center"
                      style="min-width:8rem"
                      bodyStyle="justify-content: center;text-align: center">
                <template #body="{data}">
                  <div>{{ $h.formatCurrency(data.Price) }}</div>
                </template>
              </Column>
              <Column field="LineTotal" header="Total linea" headerStyle="justify-content: center;text-align: center"
                      style="min-width:8rem"
                      bodyStyle="justify-content: center;text-align: center">
                <template #body="{data}">
                  <div>{{ $h.formatCurrency(data.LineTotal) }}</div>
                </template>
              </Column>
              <Column field="WhsCode" header="Almacén"
                      headerStyle="justify-content: center;text-align: center;"
                      style="min-width:15rem" bodyStyle="justify-content: center;text-align: center">
                <template #body="{data}">
                  <div>{{ data.WhsCode }} - {{ data.WhsName }}</div>
                </template>
              </Column>
              <Column field="Ingreso" header="Ingreso" headerStyle="text-align: center;justify-content: center;"
                      style="min-width:5rem"
                      bodyStyle="text-align: center;justify-content: center;">
                <template #body="{data}">
                  <div>
                    <Badge :value="data.Ingreso" v-if="data.Ingreso === 'Sede'" severity="success"
                           class="text-xxs h-1/2"></Badge>
                    <Badge :value="data.Ingreso" v-else severity="danger" class="text-xxs h-1/2"></Badge>
                  </div>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </template>
    </Card>
    <Card class="w-full mt-4">
      <template #content>
        <div class="w-full grid grid-cols-1 lg:grid-cols-3 gap-2 xl:gap-8">
          <div class="grid text-xxs grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 p-1 gap-2 xl:gap-8 lg:col-span-2">
            <div class="flex flex-col">
              <p class="mb-2">
                <strong>Comentarios: </strong>
              </p>
              <Textarea
                class="p-inputtext-xxs"
                disabled
                :autoResize="true"
                v-model="detailsPedido.Comments"
                rows="2"
                cols="40"/>
            </div>
            <div class="flex flex-col">
              <p class="mb-2">
                <strong>Entrada de diario: </strong>
              </p>
              <Textarea
                class="p-inputtext-xxs"
                disabled
                :autoResize="true"
                v-model="detailsPedido.JrnlMemo"
                rows="2"
                cols="40"
              />
            </div>
          </div>
          <div class="text-xxs lg:col-span-1">
            <div class="flex justify-between border-b-2 pb-3">
              <span class="text-gray-600">Total sin descuento:</span>
              <span class="">{{ $h.formatCurrency(detailsPedido.DocTotalBefore) }}</span>
            </div>
            <div class="flex justify-between my-3">
              <span class="text-gray-600">Total descuento:</span>
              <span class="">{{ $h.formatCurrency(detailsPedido.DocTotal * detailsPedido.DiscPrcnt) }}</span>
            </div>
            <div class="flex justify-between my-3">
              <span class="text-gray-600">Total IVA:</span>
              <span class="">{{ $h.formatCurrency(detailsPedido.totalIva) }}</span>
            </div>
            <div class="flex justify-between bg-blue-800 text-white rounded-lg p-2 text-sm">
              <span class="font-bold">Total:</span>
              <span class="">{{ $h.formatCurrency(detailsPedido.DocTotal) }}</span>
            </div>
          </div>
        </div>
        <div class="flex justify-between align-center mt-5">
          <Button
            v-if="$can('pharmasan.compras.logistica.recepcion-pedidos.listado-pedidos.cerrar-lineas')"
            icon="pi pi-times"
            label="Cerrar lineas"
            class="p-button-rounded p-button-danger content-center text-sm"
            @click="closeLine"
          />
          <Button
            v-if="$can('pharmasan.compras.logistica.recepcion-pedidos.listado-pedidos.copiar-a-oferta-compra')"
            icon="pi pi-send"
            label="Copiar a oferta de compra"
            class="p-button-rounded p-button-success"
            @click="createOfertaCompra"
          />
        </div>
      </template>
    </Card>
  </Dialog>
</template>

<script>
import { ref } from 'vue'
import RecepcionPedidosService from '@/apps/pharmasan/compras/logistica/services/rcp-pedidos.service'
import Swal from 'sweetalert2'
import { useRouter } from 'vue-router'

export default {
  name: 'modalDetails',
  emits: ['refresh'],
  setup (props, { emit }) {
    const displayModal = ref(false)
    const selectedCustomers = ref([])
    const detailsPedido = ref({})
    const router = useRouter()
    const _RecepcionPedidosService = ref(new RecepcionPedidosService())
    const openModal = (values) => {
      selectedCustomers.value = []
      detailsPedido.value = values
      displayModal.value = true
    }
    const closeLine = () => {
      const JsonServiceLayer = selectedCustomers.value.map((x) => {
        return {
          LineNum: x.LineNum,
          DocEntry: detailsPedido.value.DocEntry,
          LineStatus: 'bost_Close'
        }
      })
      const params = {
        DocumentLines: JsonServiceLayer
      }
      _RecepcionPedidosService.value.cerrarLineasPedidoSap(params).then(({ data }) => {
        if (data.status) {
          selectedCustomers.value = []
          Swal.fire({
            icon: 'success',
            title: 'Éxito',
            text: `${data.message}`,
            showConfirmButton: true,
            confirmButtonText: 'Ok'
          }).then((resp) => {
            if (resp.isConfirmed) {
              emit('refresh', detailsPedido.value.DocEntry)
            }
          })
        }
      })
    }
    const rowClass = (data) => {
      return data.LineStatus === 'C' ? 'lineaCerrada' : null
    }
    const onRowSelect = (event) => {
      if (event.data.LineStatus === 'C') {
        selectedCustomers.value = selectedCustomers.value.filter(x => x.LineNum !== event.data.LineNum)
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'Esta linea ya se encuentra cerrada, por favor revisar.',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
      }
      if (event.data.hasRecepcion) {
        selectedCustomers.value = selectedCustomers.value.filter(x => x.LineNum !== event.data.LineNum)
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'Esta linea tiene recepciones activas y no se puede cerrar.',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
      }
    }

    const createOfertaCompra = () => {
      /* dentro del módulo se validan las líneas que se podrían usar para copiar a oferta */
      router.push({
        name: 'pharmasan.compras.logistica.recepcion-pedidos.crear-oferta',
        params: { DocEntry: detailsPedido.value.DocEntry }
      })
    }
    const onRowSelectAll = (event) => {
      const lineasCerradas = event.data.filter((x) => x.LineStatus === 'C')
      if (lineasCerradas.length) {
        selectedCustomers.value = selectedCustomers.value.filter(x => !lineasCerradas.map(a => a.LineNum).includes(x.LineNum))
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'Existen lineas que ya se encuentran cerradas, por favor revisar.',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
      }
      const lineasConRecepcion = event.data.filter((x) => x.hasRecepcion > 0)
      if (lineasConRecepcion.length) {
        selectedCustomers.value = selectedCustomers.value.filter(x => !lineasConRecepcion.map(a => a.LineNum).includes(x.LineNum))
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'Existen lineas que tienen recepciones activas y no se pueden cerrar.',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
      }
    }
    return {
      openModal,
      displayModal,
      detailsPedido,
      selectedCustomers,
      onRowSelectAll,
      closeLine,
      rowClass,
      createOfertaCompra,
      onRowSelect
    }
  }
}
</script>

<style scoped>
.header {
  display: grid;
  box-shadow: 3px 3px 3px 4px whitesmoke;
}

.inputsHeader {
  background-color: #eef1f5;
  width: 100%;
  height: 100%;
}

.footerModal {
  display: flex;
  /*justify-content: space-between;*/
  padding: 10px;
}

::v-deep(.pi) {
  font-size: 0.8rem;
}

::v-deep(.p-column-header-content) {
  justify-content: center;
}

::v-deep(.p-card-content) {
  padding: 0rem;
}

::v-deep(.lineaCerrada) {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
</style>
